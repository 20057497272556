import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Routes from 'routes';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import './App.css';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import loginVideo from 'assets/images/video-background.mp4';
import Logo from 'ui-component/Logo';

// ==============================|| APP ||============================== //

export const staticIps = {
    '192.140.145.96': '192.140.145.96', // Hitech-Prime IP
    '85.132.31.22': '85.132.31.22', // Head Office IP
    '31.171.80.75': '31.171.80.75', // Sumgayit IP
    '89.147.210.170': '89.147.210.170', // Ganjlik IP
    '185.52.131.115': '185.52.131.115', // Bahram Bakcell Data Card
    '85.132.10.6': '85.132.10.6', // Bahram Home
    '101.53.236.37': '101.53.236.37' // Salman Bhai Home
};

const App = () => {
    const customization = useSelector((state) => state.customization);
    let [ip, setIp] = React.useState('');

    React.useEffect(() => {
        const checkIPAddress = async () => {
            try {
                const res = await axios.get('https://api.ipify.org/?format=json');
                setIp(res.data.ip);
            } catch (error) {
                console.log(error);
            }
        };
        checkIPAddress();
    }, []);

    return `${staticIps[`${ip}`]}`.toString() !== ip.toString() ? (
        ip.toString() ? (
            <div
                style={{
                    backgroundColor: 'black',
                    width: ' 100%',
                    height: '100vh',
                    position: 'relative',
                    flexDirection: 'column',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <div className="overlay"></div>
                <video id={'myVideo'} playsInline="playsInline" autoPlay muted loop>
                    <source src={loginVideo} type="video/mp4" />
                </video>

                <div
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        height: '100vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center'
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Logo width={'315'} />
                        </div>
                        <br />
                        <h1
                            style={{
                                marginTop: 40,
                                color: 'white',
                                fontSize: '80px',
                                marginBlockStart: '0em',
                                marginBlockEnd: '0em',
                                fontFamily: 'system-ui'
                            }}
                        >
                            ACCESS DENIED
                        </h1>
                        <p
                            style={{
                                textAlign: 'center',
                                color: 'white',
                                fontWeight: 800,
                                fontSize: '40px',
                                marginBlockStart: '0em',
                                marginBlockEnd: '0em',
                                fontFamily: 'system-ui'
                            }}
                        >
                            OOPS! You don't have access
                            <br />
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        bottom: '10px',
                        marginBottom: '15px'
                    }}
                >
                    <span
                        className="btn-floating btn-fb mx-1 "
                        style={{
                            textDecoration: 'none',
                            color: 'white',
                            fontFamily: 'system-ui',
                            fontWeight: 700
                        }}
                    >
                        Copyright &copy; {new Date().getFullYear()} Wonderland | Developed by
                        <a
                            href="http://www.hitech-prime.com/"
                            style={{
                                color: 'white',
                                textDecoration: 'none',
                                fontWeight: 500
                            }}
                            target="blank"
                        >
                            &nbsp;HiTech Prime
                        </a>
                    </span>
                </div>
            </div>
        ) : null
    ) : (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <ToastContainer
                        position="bottom-left"
                        autoClose={2000}
                        limit={1}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss={false}
                        draggable
                        pauseOnHover
                    />
                    <CssBaseline />
                    <NavigationScroll>
                        <Routes />
                    </NavigationScroll>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
